import Icon from '@ant-design/icons';
import { Menu } from 'antd';
import { DesktopMenu } from 'assets/styles/globalStyledComponents';
import { translate } from 'components/Utility/i18n';
import { getOptions } from 'containers/global/Sidebar/options';
import { ID } from 'definitions/constants-fe';
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RootState } from 'redux/root-saga';
import { CUser } from '../../../../../shared/classes';

interface ClassPropsDirect {
  routechanged: boolean;
}

interface ClassPropsRedux {
  current: string;
  location: any;
  history: any;
  rx_userAccount: CUser | null;
  rx_remoteConfigAccessPinboard: boolean;
}

type ClassProps = ClassPropsDirect & ClassPropsRedux;

interface ClassState {
  current: string;
}

class HorizontalMenu extends Component<ClassProps, ClassState> {
  constructor(props: ClassPropsDirect) {
    // @ts-ignore
    super(props);
    this.state = {
      current: '',
    };
  }

  componentDidMount() {
    const { pathname } = this.props.location;
    let current = '';
    getOptions(this.props.rx_remoteConfigAccessPinboard).forEach((value) => {
      if (pathname.indexOf(value.key) !== -1) {
        current = value.key;
      }
    });
    this.setState({ current });
  }

  componentDidUpdate(prevProps: ClassProps) {
    if (this.props.routechanged !== prevProps.routechanged) {
      const { pathname } = this.props.location;
      let current = '';
      getOptions(this.props.rx_remoteConfigAccessPinboard).forEach((value) => {
        if (pathname.indexOf(value.key) !== -1) {
          current = value.key;
        }
      });
      this.setState({ current });
    }
  }

  render() {
    const { rx_userAccount, rx_remoteConfigAccessPinboard } = this.props;
    const { current } = this.state;
    const url = this.props.history.location.pathname.split('/');

    return (
      <DesktopMenu>
        {/* Menu component (Header menu) start */}
        {url.includes(ID.immoapp) && (
          <Menu
            selectedKeys={[current]}
            mode="horizontal"
            items={getOptions(rx_remoteConfigAccessPinboard)
              .map((ele) => {
                if ((rx_userAccount?.role ?? -1) < ele.minRole) {
                  return null;
                }
                return {
                  key: ele.key,
                  label: (
                    <>
                      <div>
                        <Icon
                          component={ele.icon}
                          className="mb-1 !text-[25px]"
                        />
                        <div>{translate(ele.label)}</div>
                      </div>
                    </>
                  ),
                  onClick: () =>
                    this.props.history.push({
                      pathname: `/${ele.key}`,
                    }),
                  style: current.includes(ele.key)
                    ? { backgroundColor: 'rgba(0,0,0,0.2)' }
                    : undefined,
                };
              })
              .filter((ele) => ele)}
          />
        )}
        {/* Menu component (Header menu) end */}
      </DesktopMenu>
    );
  }
}

export default withRouter(
  // @ts-ignore
  connect((state: RootState) => ({
    rx_remoteConfigAccessPinboard: state.ImmoApp.rx_remoteConfigAccessPinboard,
    rx_userAccount: state.GL_Auth.rx_userAccount,
  }))(HorizontalMenu),
);
