import { transition } from 'assets/styles/style-util';
import styled from 'styled-components';
// @ts-ignore
import { palette, size } from 'styled-theme';

const TopbarWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 1000;

  .isomorphicTopbar {
    display: flex;
    justify-content: space-between;
    background-color: ${palette('custom', 0)};
    box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.15);
    padding: ${(props: any) =>
      props['data-rtl'] === 'rtl' ? '0 35px 0 35px' : '0 35px 0 35px'};
    z-index: 1000;
    ${transition()};

    @media ${size('max_md')} {
      padding: ${(props: any) =>
        props['data-rtl'] === 'rtl'
          ? '0px 260px 0px 15px !important'
          : '0px 15px 0px 260px !important'};
    }

    &.collapsed {
      padding: ${(props: any) =>
        props['data-rtl'] === 'rtl' ? '0 35px 0 35px' : '0 35px 0 35px'};
      @media ${size('max_md')} {
        padding: ${(props: any) =>
          props['data-rtl'] === 'rtl'
            ? '0px 15px !important'
            : '0px 15px !important'};
      }
    }
    .isoLeft,
    .isoCenter {
      display: flex;
      align-items: center;
      .img-section {
        margin-left: -6px;
        a {
          padding: 6px;
          display: flex;
          &:hover {
            opacity: 0.85;
          }
          img {
            max-height: 13px;
          }
        }
      }
    }
    .isoLeft {
      @media ${size('max_md')} {
        margin: ${(props: any) =>
          props['data-rtl'] === 'rtl' ? '0 0 0 20px' : '0 0 0 0'};
      }
    }
    .isoRight {
      display: flex;
      align-items: center;
      margin: 0px;

      .icon-section {
        display: flex;

        .isoClientLogo {
          display: flex;
          align-items: center;
          margin-left: 20px;
          .ant-spin-blur {
            img {
              border: none;
            }
          }
          img {
            padding: 8px;
            border: 1px solid ${palette('border', 0)};
            border-radius: 4px;
            &:hover {
              border-color: ${palette('border', 1)};
            }
          }
          &.hide {
            @media ${size('max_xs')} {
              display: none;
            }
          }
        }

        .userContent .ant-popover-inner {
          padding: 0;
        }

        li {
          cursor: pointer;
          line-height: normal;
          position: relative;

          &.main-icon {
            margin-left: 0px;
            &.immoapp {
              border-bottom: 2px solid ${palette('tool', 1)};
            }
            &.immofonds {
              border-bottom: 2px solid ${palette('tool', 0)};
            }
            &.immomove {
              border-bottom: 2px solid ${palette('tool', 2)};
            }
            &.account,
            &.statistics,
            &.team,
            &.settings {
              border-bottom: 2px solid ${palette('tool', 3)};
            }
            .isoImgWrapper {
              background-color: transparent !important;
            }
          }

          i {
            font-size: 12px;
            color: ${palette('text', 0)};
          }

          .isoIconWrapper {
            position: relative;
            line-height: normal;

            span {
              font-size: 12px;
              color: ${palette('custom', 0)};
              background-color: ${palette('secondary', 1)};
              width: 20px;
              height: 20px;
              display: -webkit-inline-flex;
              display: -ms-inline-flex;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              line-height: 20px;
              position: absolute;
              top: -8px;
              left: ${(props: any) =>
                props['data-rtl'] === 'rtl' ? 'inherit' : '10px'};
              right: ${(props: any) =>
                props['data-rtl'] === 'rtl' ? '10px' : 'inherit'};
              border-radius: 50%;
            }
          }

          &.isoUser {
            margin-left: 15px;
          }
          &.isoMail {
            .isoIconWrapper {
              span {
                background-color: ${palette('color', 0)};
              }
            }
          }

          &.isoNotify {
            .isoIconWrapper {
              span {
                background-color: ${palette('primary', 2)};
              }
            }
          }

          &.isoMsg {
            .isoIconWrapper {
              span {
                background-color: ${palette('color', 1)};
              }
            }
          }

          &.isoCart {
            .isoIconWrapper {
              span {
                background-color: ${palette('color', 2)};
              }
            }
          }

          &.isoUser {
            height: 38px;
            opacity: 0.8;
            color: #222;
            margin-top: auto;
            margin-bottom: auto;
            -webkit-transition: opacity 0.2s;
            -moz-transition: opacity 0.2s;
            -o-transition: opacity 0.2s;
            transition: opacity 0.2s;

            .isoEmail {
              display: inline-block;
              margin: 12px 10px 12px 0;
            }
            @media ${size('max_md')} {
              .isoEmail {
                display: none;
              }
            }
            .isoImgWrapper {
              float: right;
              width: 38px;
              height: 38px;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
              border-radius: 50%;
              background-color: ${palette('secondary', 3)};

              #hamburger {
                position: relative;
                width: 100%;
                height: 12px;

                span {
                  position: absolute;
                  margin: auto;
                  left: 0px;
                  right: 0px;
                  height: 2px;
                  width: 14px;
                  border-radius: 2px;
                  background-color: ${palette('custom', 7)};
                  opacity: 1;
                  transform: rotate(0deg);
                  transition: 0.25s ease-in-out;
                  transform-origin: left center;

                  &:nth-child(1) {
                    top: 0px;
                  }
                  &:nth-child(2) {
                    top: 5px;
                  }
                  :nth-child(3) {
                    top: 10px;
                  }
                }
              }

              &.menuOpen {
                #hamburger {
                  span {
                    &:nth-child(1) {
                      transform: rotate(45deg);
                      width: 18px;
                      top: -1px;
                      left: 15%;
                    }
                    &:nth-child(2) {
                      opacity: 0;
                      width: 0;
                    }
                    &:nth-child(3) {
                      transform: rotate(-45deg);
                      width: 18px;
                      top: 12px;
                      left: 15%;
                    }
                  }
                }
              }

              img {
                width: 20px;
                height: auto;
                object-fit: cover;
              }
              span {
                font-weight: bold;
              }
              .userActivity {
                display: none !important;
                width: 10px;
                height: 10px;
                display: block;
                background-color: ${palette('color', 3)};
                position: absolute;
                bottom: 0;
                right: 3px;
                border: 1px solid #ffffff;
                border-radius: 50%;
              }
            }
            .ant-badge {
              position: absolute;
              top: 6px;
              right: 6px;
            }
          }
          &.isoUser:hover {
            opacity: 1;
          }
        }
      }
      .ant-spin-blur {
        .img-section {
          min-width: 70px;
        }
      }
      .img-section {
        max-width: 90px;
        width: 100%;
        img {
          max-width: 100%;
          max-height: 48px;
        }
        @media ${size('max_md')} {
          max-width: 80px;
        }
      }
    }
  }

  // Dropdown
  .ant-popover {
    .ant-popover-inner {
      .ant-popover-inner-content {
        .isoDropdownContent {
          display: flex;
          flex-direction: column;
          position: absolute;
          top: 0;
          right: 0;
          background-color: ${palette('custom', 0)};
          width: 360px;
          min-width: 160px;
          flex-shrink: 0;
          border-radius: 5px;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
          ${transition()};

          @media ${size('max_md')} {
            width: 310px;
          }

          .isoDropdownHeader {
            border-bottom: 1px solid ${palette('custom', 3)};
            margin-bottom: 0px;
            padding: 15px 30px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            h3 {
              font-size: 14px;
              font-weight: 500;
              color: ${palette('text', 0)};
              text-align: center;
              text-transform: uppercase;
              margin: 0;
            }
          }

          .isoDropdownBody {
            width: 100%;
            height: 300px;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;
            background-color: ${palette('grayscale', 6)};

            .isoDropdownListItem {
              padding: 15px 30px;
              flex-shrink: 0;
              text-decoration: none;
              display: flex;
              flex-direction: column;
              text-decoration: none;
              width: 100%;
              ${transition()};

              &:hover {
                background-color: ${palette('grayscale', 3)};
              }

              .isoListHead {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 5px;
              }

              h5 {
                font-size: 13px;
                font-weight: 500;
                color: ${palette('text', 0)};
                margin-top: 0;
              }

              p {
                font-size: 12px;
                font-weight: 400;
                color: ${palette('text', 2)};
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }

              .isoDate {
                font-size: 11px;
                color: ${palette('grayscale', 1)};
                flex-shrink: 0;
              }
            }
          }

          .isoViewAllBtn {
            font-size: 13px;
            font-weight: 500;
            color: ${palette('text', 2)};
            padding: 10px 15px 20px;
            display: flex;
            text-decoration: none;
            align-items: center;
            justify-content: center;
            text-align: center;
            ${transition()};

            &:hover {
              color: ${palette('primary', 0)};
            }
          }

          .isoDropdownFooterLinks {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 30px 20px;

            a {
              font-size: 13px;
              font-weight: 500;
              color: ${palette('text', 0)};
              text-decoration: none;
              padding: 10px 20px;
              line-height: 1;
              border: 1px solid ${palette('border', 1)};
              display: flex;
              align-items: center;
              justify-content: center;
              ${transition()};

              &:hover {
                background-color: ${palette('primary', 0)};
                border-color: ${palette('primary', 0)};
                color: ${palette('custom', 0)};
              }
            }

            h3 {
              font-size: 14px;
              font-weight: 500;
              color: ${palette('text', 0)};
              line-height: 1.3;
            }
          }

          &.withImg {
            .isoDropdownListItem {
              display: flex;
              flex-direction: row;

              .isoImgWrapper {
                width: 35px;
                height: 35px;
                overflow: hidden;
                margin-right: 15px;
                display: -webkit-inline-flex;
                display: -ms-inline-flex;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                flex-shrink: 0;
                background-color: ${palette('grayscale', 9)};
                border-radius: 50%;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }

              .isoListContent {
                width: 100%;
                display: flex;
                flex-direction: column;

                .isoListHead {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  margin-bottom: 10px;
                }

                h5 {
                  margin-bottom: 0;
                  padding-right: 15px;
                }

                .isoDate {
                  font-size: 11px;
                  color: ${palette('grayscale', 1)};
                  flex-shrink: 0;
                }

                p {
                  white-space: normal;
                  line-height: 1.5;
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default TopbarWrapper;
