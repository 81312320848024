import Icon, {
  LogoutOutlined,
  PieChartOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Badge, Divider, Popover, Spin, Tooltip } from 'antd';
import { ReactComponent as ImmoAppIcon } from 'assets/images/app-icon.svg';
import { ReactComponent as ImmoMoveIcon } from 'assets/images/move-icon.svg';
import toolSwitcher from 'assets/images/tool-switcher.svg';
import {
  LinkStyle,
  NormalTextLarge,
  SubLabelText,
} from 'assets/styles/globalStyledComponents';
import { translate } from 'components/Utility/i18n';
import { getOptions } from 'containers/global/Sidebar/options';
import { ID, ProductStrings } from 'definitions/constants-fe';
import jwtDecode from 'jwt-decode';
import Firebase from 'library/firebase';
import {
  clearUserRole,
  getToken,
  getUserRole,
} from 'library/localStorageHelper';
import _ from 'lodash';
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import authActions from 'redux/global/auth/actions';
import actions from 'redux/immoapp/actions';
import { RootState } from 'redux/root-saga';
import { PAGE, TOOL } from 'route.constants';
import { CUser } from '../../../../../shared/classes';
import { ACCOUNT_ROLE, STORAGE } from '../../../../../shared/constants';
import { ICommonSettings } from '../../../../../shared/types';
import { TopbarDropdownWrapper, UserContent } from './topbarDropdown.style';

interface ClassPropsDirect {
  switcherIconBorderClass: string | null;
}

interface ClassPropsRedux {
  rx_userAccount: CUser | null;
  rx_users: CUser[];
  history: any;
  rx_logoUpdateCounter: number;
  rx_commonSettings: ICommonSettings | null;
  rx_remoteConfigAccessPinboard: boolean;
  subscribeCommonSettings: () => void;
  subscribeUsers: () => void;
  logout: () => void;
  getRemoteConfigAccessComments: () => void;
  getRemoteConfigAccessPinboard: () => void;
  getUser: ({ uid }: { uid: string }) => void;
  setUser: (userData: CUser) => void;
}

type ClassProps = ClassPropsDirect & ClassPropsRedux;

interface ClassState {
  logo: string | null;
  userMenu: boolean;
  switcher: boolean;
  isMounted: boolean;
  pendingUserCount: number;
  communicationDisabled: boolean;
}

class TopbarUser extends Component<ClassProps, ClassState> {
  constructor(props: ClassPropsDirect) {
    // @ts-ignore
    super(props);
    this.state = {
      logo: null,
      userMenu: false,
      switcher: false,
      isMounted: false,
      pendingUserCount: 0,
      communicationDisabled: false,
    };
  }

  componentDidMount() {
    const token = getToken();
    const { user_id }: { user_id: string } =
      token == null ? { user_id: '' } : jwtDecode(token);
    this.props.getUser({ uid: user_id });
    this.props.subscribeCommonSettings();
    this.props.subscribeUsers();
    this.props.getRemoteConfigAccessComments();
    this.props.getRemoteConfigAccessPinboard();
    this.loadLogo();
  }

  componentDidUpdate(prevProps: ClassProps) {
    if (this.props.rx_logoUpdateCounter !== prevProps.rx_logoUpdateCounter) {
      this.setState({ isMounted: false });
      this.loadLogo();
    }
    if (
      this.props.rx_commonSettings != null &&
      this.props.rx_commonSettings !== prevProps.rx_commonSettings
    ) {
      this.setState({
        communicationDisabled:
          this.props.rx_commonSettings?.auto_send_emails !== true ||
          this.props.rx_commonSettings?.auto_send_push !== true,
      });
    }

    if (this.props.rx_userAccount !== prevProps.rx_userAccount) {
      if (
        this.props.rx_userAccount != null &&
        parseInt(getUserRole() ?? '-1') !== this.props.rx_userAccount.role
      ) {
        // We need to reload to resubscribe all realtime listeners
        // Since this happens almost never, this solution is perfectly fine
        clearUserRole();
        this.props.logout();
      }
      this.props.subscribeUsers();
    }

    if (this.props.rx_users !== prevProps.rx_users) {
      // Set pending users
      const pendingUser = this.props.rx_users.filter((u) => u.approved === 0);
      this.setState({ pendingUserCount: pendingUser.length });

      // Set my user data
      const token = getToken();
      const { user_id }: { user_id: string } =
        token == null ? { user_id: '' } : jwtDecode(token);
      const myNewUserData = this.props.rx_users.find(
        (u) => u.uid === user_id && u.approved === 1,
      );
      if (myNewUserData == null) {
        // This happens when localstorage contains token from other ENV --> Logout
        this.props.logout();
      } else {
        if (
          !_.isEqual(
            _.omit(this.props.rx_userAccount, [ID.time_last_login]),
            _.omit(myNewUserData, [ID.time_last_login]),
          )
        ) {
          this.props.setUser(myNewUserData);
        }
      }
    }
  }

  loadLogo = async () => {
    await Firebase.loadSingleFile(
      `${STORAGE.settings}/${STORAGE.client_logo}`,
      '',
      '',
    ).then((url) => {
      this.setState({ logo: url.mainUrl });
    });
  };

  renderLogo = (logo: string) => {
    return (
      <a
        href={`${process.env.REACT_APP_CLIENT_WEBSITE_URL}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={logo}
          onLoad={() => this.setState({ isMounted: true })}
          alt={`${process.env.REACT_APP_CLIENT_NAME_FULL} Logo`}
        />
      </a>
    );
  };

  switchTool = (tool: string, hasAccess: boolean) => {
    this.setState({ switcher: hasAccess ? false : true });
    if (hasAccess) {
      let baseUrl = null;
      switch (tool) {
        case ID.immoapp:
          baseUrl = `/${TOOL.IMMOAPP}/${PAGE.IA_DASHBOARD}`;
          break;
        case ID.immomove:
          baseUrl = `/${TOOL.IMMOMOVE}/${PAGE.IM_PROCESSES}`;
          break;
        default:
          break;
      }
      if (baseUrl) {
        this.props.history.push({ pathname: baseUrl });
      } else {
        return false;
      }
    }
  };

  render() {
    const {
      rx_userAccount,
      switcherIconBorderClass,
      rx_remoteConfigAccessPinboard,
    } = this.props;

    const url = this.props.history.location.pathname.split('/');

    const immoAppAccess = process.env.REACT_APP_IA === 'true';

    const immoMoveAccess =
      process.env.REACT_APP_IM === 'true' &&
      (rx_userAccount?.role ?? -1) >= ACCOUNT_ROLE.manager.value;

    const multipleToolsActive =
      [immoAppAccess, immoMoveAccess].reduce(
        (a, b) => a + (b === true ? 1 : 0),
        0,
      ) >= 2;

    const {
      isMounted,
      logo,
      pendingUserCount,
      communicationDisabled,
      switcher,
      userMenu,
    } = this.state;

    let userInitials = '..';
    if (
      rx_userAccount?.first_name != null &&
      rx_userAccount?.last_name != null
    ) {
      const letterOne = rx_userAccount.first_name
        .replace(/[^a-zA-Z]/g, '')
        .substring(0, 1)
        .toUpperCase();
      const letterTwo = rx_userAccount.last_name
        .replace(/[^a-zA-Z]/g, '')
        .substring(0, 1)
        .toUpperCase();
      userInitials = letterOne + letterTwo;
    }
    const userContent = (
      <UserContent>
        <ul>
          {/* Account */}
          {(rx_userAccount?.role ?? -1) >= ACCOUNT_ROLE.tenant_normal.value &&
            rx_userAccount?.first_name != null &&
            rx_userAccount?.last_name != null &&
            rx_userAccount?.email != null && (
              <>
                <li className="cursor-auto">
                  <LinkStyle className="cursor-auto bg-white">
                    <div className="user-initials">
                      <span>{userInitials}</span>
                    </div>
                    <div className="user-details">
                      <NormalTextLarge>
                        {`${rx_userAccount.first_name} ${rx_userAccount.last_name}`}
                      </NormalTextLarge>
                      <SubLabelText className="no-margin">
                        {`${rx_userAccount.email}`}
                      </SubLabelText>
                    </div>
                  </LinkStyle>
                </li>
                <Divider className="small-margin" />
              </>
            )}
          {/* Mobile */}
          {window.innerWidth < 768 && url.includes(ID.immoapp) && (
            <>
              {getOptions(rx_remoteConfigAccessPinboard).map(
                (ele) =>
                  (rx_userAccount?.role ?? -1) >= ele.minRole && (
                    <li key={ele.key}>
                      <LinkStyle
                        onClick={() => {
                          this.setState({ userMenu: false });
                          this.props.history.push({
                            pathname: `/${ele.key}`,
                          });
                        }}
                      >
                        <Icon component={ele.icon} />
                        {translate(ele.label)}
                      </LinkStyle>
                    </li>
                  ),
              )}
              <Divider className="small-margin" />
            </>
          )}
          {/* Staff */}
          {(rx_userAccount?.role ?? -1) >= ACCOUNT_ROLE.manager.value && (
            <>
              <li>
                <LinkStyle
                  onClick={() => {
                    this.setState({ userMenu: false });
                    this.props.history.push({
                      pathname: `/${TOOL.STATISTICS}`,
                    });
                  }}
                >
                  <PieChartOutlined />
                  {translate('global.statistics')}
                </LinkStyle>
              </li>
              <li>
                <LinkStyle
                  onClick={() => {
                    this.setState({ userMenu: false });
                    this.props.history.push({
                      pathname: `/${TOOL.SETTINGS}`,
                    });
                  }}
                >
                  <SettingOutlined />
                  {translate('menu.managePlatform')}
                  {(pendingUserCount > 0 || communicationDisabled) && (
                    <Badge
                      className={
                        communicationDisabled
                          ? ID.communicationDisabledBadge
                          : ID.pendingUserBadge
                      }
                      dot
                    />
                  )}
                </LinkStyle>
              </li>
              <Divider className="small-margin" />
            </>
          )}
          <li>
            <LinkStyle onClick={() => this.props.logout()} className="danger">
              <LogoutOutlined />
              {translate('global.logout')}
            </LinkStyle>
          </li>
        </ul>
      </UserContent>
    );

    const switcherContent = (
      <TopbarDropdownWrapper className="isoUserDropdown icon-box">
        <ul>
          <Tooltip
            placement="bottom"
            title={!immoAppAccess ? translate('global.noAccessForTool') : ''}
          >
            <li
              onClick={this.switchTool.bind(this, ID.immoapp, immoAppAccess)}
              className={url.includes(ID.immoapp) ? 'active' : ''}
            >
              <ImmoAppIcon className={immoAppAccess ? 'icon-app' : ''} />
              <h4>{ProductStrings.immoapp}</h4>
            </li>
          </Tooltip>
          <Tooltip
            placement="bottom"
            title={!immoMoveAccess ? translate('global.noAccessForTool') : ''}
          >
            <li
              onClick={this.switchTool.bind(this, ID.immomove, immoMoveAccess)}
              className={url.includes(ID.immomove) ? 'active' : ''}
            >
              <ImmoMoveIcon className={immoMoveAccess ? 'icon-move' : ''} />
              <h4>{ProductStrings.immomove}</h4>
            </li>
          </Tooltip>
        </ul>
      </TopbarDropdownWrapper>
    );

    return (
      <div className="icon-section">
        {(rx_userAccount?.role ?? -1) >= ACCOUNT_ROLE.janitor.value &&
          multipleToolsActive && (
            <Popover
              content={switcherContent}
              trigger="click"
              open={switcher}
              onOpenChange={() => this.setState({ switcher: !switcher })}
              placement="bottomRight"
            >
              <li className={`isoUser main-icon ${switcherIconBorderClass}`}>
                <div className="isoImgWrapper">
                  <img src={toolSwitcher} alt="tool switch"></img>
                </div>
              </li>
            </Popover>
          )}
        <Popover
          content={userContent}
          trigger="click"
          open={userMenu}
          onOpenChange={() => this.setState({ userMenu: !userMenu })}
          placement="bottomRight"
          // @ts-ignore
          getPopupContainer={(trigger) => trigger.parentNode}
          overlayClassName="userContent"
        >
          <li className="isoUser">
            <div className={`isoImgWrapper ${userMenu ? 'menuOpen' : ''}`}>
              <div id="hamburger">
                <span />
                <span />
                <span />
              </div>
              <span className="userActivity online" />
            </div>
            {(rx_userAccount?.role ?? -1) >= ACCOUNT_ROLE.manager.value &&
              (pendingUserCount > 0 || communicationDisabled) && (
                <Badge
                  className={
                    communicationDisabled
                      ? ID.communicationDisabledBadge
                      : ID.pendingUserBadge
                  }
                  dot
                />
              )}
          </li>
        </Popover>
        <li
          className={`isoClientLogo ${
            (rx_userAccount?.role ?? -1) >= ACCOUNT_ROLE.manager.value
              ? 'hide'
              : 'show'
          }`}
        >
          <Spin spinning={!isMounted}>
            <div className="img-section">
              {logo ? this.renderLogo(logo) : <span />}
            </div>
          </Spin>
        </li>
      </div>
    );
  }
}

export default withRouter(
  // @ts-ignore
  connect(
    (state: RootState) => ({
      ...state.ImmoApp,
      rx_userAccount: state.GL_Auth.rx_userAccount,
    }),
    { ...actions, ...authActions },
  )(TopbarUser),
);
