import { QuestionnaireScreen } from 'assets/definitions/IMQuestionnaire';
import { ID, USER_SOURCE } from 'definitions/constants-fe';
import Firebase from 'library/firebase';
import {
  genRandomPassword,
  handleTimeStamps,
  showMessage,
} from 'library/functionHelper';
import { Reporting } from 'library/sentry/reporting';
import _ from 'lodash';
import omit from 'lodash/omit'; // TODO optimize
import {
  all,
  call,
  cancel,
  delay,
  fork,
  put,
  take,
  takeEvery,
} from 'redux-saga/effects';
import actionsApp from 'redux/global/app/actions';
import {
  ACCOUNT_ROLE,
  DB,
  IM_PROCESS_STATUS,
  SPACE_FUNCTIONS,
  STORAGE,
} from '../../../../shared/constants';
import actions from './actions';
const {
  database,
  rsfFirestore,
  refFireFunction,
  secondAppAuth,
  snapshotAsArray,
} = Firebase;

// REALTIME
let listenerTerminationProcesses = null;
function* subscribeTerminationProcesses() {
  try {
    // This makes sure that the database listener is only active once
    if (listenerTerminationProcesses?.isRunning() === true) {
      return;
    }
    yield put(actions.subscribeTerminationProcesses.request());
    listenerTerminationProcesses = yield fork(
      rsfFirestore.syncCollection,
      database
        .collection(DB.im_termination_process)
        .orderBy(ID.time_created, ID.desc),
      {
        successActionCreator: actions.subscribeTerminationProcesses.success,
        failureActionCreator: actions.subscribeTerminationProcesses.failure,
        transform: (payload) => snapshotAsArray(payload, true),
      },
    );
    // If cancel is called, the code continues to execute
    yield take(actions.cancelTerminationProcesses.TRIGGER);
    // And we cancel the database listener
    yield cancel(listenerTerminationProcesses);
    listenerTerminationProcesses = null;
  } catch (error) {
    Reporting.Error(error);
    listenerTerminationProcesses = null;
    yield put(
      actions.subscribeTerminationProcesses.failure(
        'global.generalErrorMessage',
      ),
    );
  } finally {
  }
}

function* getTerminationProcessDetails({ payload }) {
  try {
    const termRef = yield call(
      rsfFirestore.getDocument,
      database.collection(DB.im_termination_process).doc(payload),
    );
    const data = termRef.data();
    if (!_.isEmpty(data.files_common)) {
      const cmnFilesRef = yield call(
        rsfFirestore.getDocument,
        database.collection(DB.im_files_common).doc(DB.doc_files_common),
      );
      const commonFiles = cmnFilesRef.data().files.filter((file) => {
        return data.files_common.includes(file.id);
      });
      data.files_process = data.files_process.concat(commonFiles);
    }
    yield put(actions.getTerminationProcessDetails.success(data));
  } catch (error) {
    Reporting.Error(error);
    yield put(
      actions.getTerminationProcessDetails.failure(
        'global.generalErrorMessage',
      ),
    );
  } finally {
    yield delay(1);
    yield put(actions.getTerminationProcessDetails.fulfill());
  }
}

function* getCommonFiles() {
  try {
    const snapshot = yield call(
      rsfFirestore.getDocument,
      database.collection(DB.im_files_common).doc(DB.doc_files_common),
    );
    const data = snapshot.data().files;
    yield put(actions.getCommonFiles.success(data));
  } catch (error) {
    Reporting.Error(error);
    yield put(actions.getCommonFiles.failure('global.generalErrorMessage'));
  } finally {
    yield delay(1);
    yield put(actions.getCommonFiles.fulfill());
  }
}

function* terminationAction({ payload }) {
  const { actionName, id, overwriteUserCredentials, filesArray, ...record } =
    payload;
  try {
    switch (actionName) {
      case ID.delete:
        yield call(
          rsfFirestore.deleteDocument,
          `${DB.im_termination_process}/${id}`,
        );
        yield put(
          actions.terminationAction.success('immoMoveHome.terminationDeleted'),
        );
        break;
      case ID.update:
        const hasNewFiles = filesArray?.some(
          (f) =>
            f.size != null || f.originFileObj != null || f.isEdited === true,
        );
        const hasChangedFiles =
          filesArray != null &&
          record.files_process != null &&
          filesArray.length !== record.files_process.length;
        if (hasNewFiles || hasChangedFiles) {
          showMessage('upload.inprogress', null, ID.loading, ID.key);
          const files = yield Firebase.adminFilesToStorageFiles(
            STORAGE.immomove_files_process,
            filesArray,
          );
          showMessage(
            `global.${
              filesArray.length > record.files_process?.length
                ? 'filesUploaded'
                : 'filesUpdated'
            }`,
            null,
            ID.success,
            ID.key,
          );
          record.files_process = files;
        } else if (record.files_process == null || filesArray.length === 0) {
          record.files_process = [];
        }
        record.time_created = handleTimeStamps(record.time_created);
        record.time_updated = handleTimeStamps();
        if (record.time_invited != null) {
          record.time_invited = handleTimeStamps(record.time_invited);
        }
        if (record.time_voucher_requested != null) {
          record.time_voucher_requested = handleTimeStamps(
            record.time_voucher_requested,
          );
        }
        yield database
          .collection(DB.im_termination_process)
          .doc(id)
          .set(record);
        const userDoc = yield database
          .collection(DB.ia_users)
          .doc(record.tenant.uid)
          .get();
        // User account might have been removed in the meantime
        if (userDoc.exists) {
          userDoc.ref.update({
            first_name: record.tenant.first_name,
            last_name: record.tenant.last_name,
            last_name_ci: record.tenant.last_name.toLowerCase(),
          });
        }
        yield put(
          actions.terminationAction.success('immoMoveHome.terminationUpdated'),
        );
        break;
      case ID.insert:
        // 1) If email belongs to previous tenant
        //    A) if accepted to overwrite: just set a new PW
        //    B) else: show overwrite error box
        // 2) If email belongs to previous owner/staff, show warning and stop
        // 3) If email does not exist yet, continue normally

        // Show loading message
        const msgRef = showMessage(
          'immoMoveHome.newTerminationAdding',
          null,
          ID.loading,
        );
        // Check if user email and/or termination process exists
        let setNewPasswordForUser = null;
        const querySnapshotUser = yield call(
          rsfFirestore.getCollection,
          database
            .collection(DB.ia_users)
            .where(ID.email, '==', record.tenant.email),
        );
        if (!querySnapshotUser.empty) {
          const previousUser = querySnapshotUser.docs[0];
          // Check previous user role, only continue if it is a tenant
          if (previousUser.data().role <= ACCOUNT_ROLE.tenant_normal.value) {
            if (overwriteUserCredentials) {
              setNewPasswordForUser = previousUser;
            } else {
              // Hide loading message
              msgRef();
              yield put(actions.terminationAction.request(true));
              return;
            }
          } else {
            // Hide loading message
            msgRef();
            // Previous user role is too high, show warning
            yield put(actions.terminationAction.request(false));
            return;
          }
        }
        // Generate new PW and encrypt it on the server
        const accountPassword = genRandomPassword(8);
        const accPwEncr = (yield refFireFunction.httpsCallable(
          SPACE_FUNCTIONS.gl_encrypt,
        )({
          payload: accountPassword,
        })).data;
        record.tenant.access_code = accPwEncr;
        record.tenant.last_name_ci = record.tenant.last_name.toLowerCase();
        if (setNewPasswordForUser) {
          // Set new user password via cloud function (below)
          record.tenant.uid = setNewPasswordForUser.id;
          // Update the users collection's name with the new data
          yield call(
            rsfFirestore.updateDocument,
            `${DB.ia_users}/${record.tenant.uid}`,
            {
              first_name: record.tenant.first_name,
              last_name: record.tenant.last_name,
              last_name_ci: record.tenant.last_name_ci,
            },
          );
        } else {
          // Create new firebase user
          const userRecord = yield secondAppAuth.createUserWithEmailAndPassword(
            record.tenant.email,
            accountPassword,
          );
          record.tenant.uid = userRecord.user.uid;
          // Create user in DB
          const user = {
            ...omit(record.tenant, ['salutation', 'uid', 'access_code']),
          };
          user.approved = 1;
          user.mandate_data = {};
          user.mandate_ids = [];
          user.person_id = -1;
          user.role = ACCOUNT_ROLE.tenant_restricted.value;
          user.source = USER_SOURCE.webapp_immomove.value;
          yield call(
            rsfFirestore.setDocument,
            `${DB.ia_users}/${record.tenant.uid}`,
            user,
          );
        }
        // Create termination process
        if (filesArray?.length > 0) {
          const files = yield Firebase.adminFilesToStorageFiles(
            STORAGE.immomove_files_process,
            filesArray,
          );
          record.files_process = files;
        } else {
          record.files_process = [];
        }
        const processRef = yield call(
          rsfFirestore.addDocument,
          DB.im_termination_process,
          omit(record, [ID.action]),
        );
        yield refFireFunction.httpsCallable(SPACE_FUNCTIONS.im_tenant_created)({
          tenant: record.tenant,
          staff_member: record.staff_member,
          process_id: processRef.id,
          set_new_password: setNewPasswordForUser != null,
        });
        // Hide loading message
        msgRef();
        // Return
        yield put(
          actions.terminationAction.success(
            'immoMoveHome.newTerminationCreated',
          ),
        );
        break;
      default:
        Reporting.Error(new Error());
        break;
    }
    // In case of success close modal
    yield put(actionsApp.setModal(ID.none));
  } catch (error) {
    Reporting.Error(error);
    yield put(actions.terminationAction.failure('global.generalErrorMessage'));
  } finally {
    yield delay(1);
    yield put(actions.terminationAction.fulfill());
    if (actionName === ID.update) {
      yield put(actions.getTerminationProcessDetails(id));
    }
  }
}

function* updateTerminationStatus({ payload }) {
  const { id, status } = payload;
  try {
    yield database
      .collection(DB.im_termination_process)
      .doc(id)
      .update({ status });
    yield put(actions.updateTerminationStatus.success());
  } catch (error) {
    Reporting.Error(error);
    yield put(
      actions.updateTerminationStatus.failure('global.generalErrorMessage'),
    );
  } finally {
    yield delay(1);
    yield put(actions.updateTerminationStatus.fulfill());
  }
}

function* resendAccess({ payload }) {
  const msgRef = showMessage(
    'process.resendingAccessCode',
    null,
    ID.loading,
    ID.key,
  );
  try {
    yield refFireFunction.httpsCallable(SPACE_FUNCTIONS.im_resend_access_code)({
      email: payload.email,
      zip_code: payload.zip_code,
    });
    yield put(actions.resendAccess.success('process.resendAccessCodeSuccess'));
  } catch (error) {
    Reporting.Error(error);
    yield put(actions.resendAccess.failure('process.resendAccessCodeError'));
  } finally {
    msgRef();
    yield delay(1);
    yield put(actions.resendAccess.fulfill());
  }
}

function* sendVoucher({ payload }) {
  try {
    showMessage('end.voucherRequestSending', null, ID.loading, ID.key);
    const res = yield refFireFunction.httpsCallable(
      SPACE_FUNCTIONS.im_send_voucher,
    )({
      process_id: payload,
    });
    showMessage('end.voucherRequestSent', null, ID.success, ID.key);
    yield put(actions.sendVoucher.success(res.data.timestamp / 1000));
  } catch (error) {
    Reporting.Error(error);
    yield put(actions.sendVoucher.failure('global.generalErrorMessage'));
  } finally {
    yield delay(1);
    yield put(actions.sendVoucher.fulfill());
  }
}

function* saveAnswers({ payload }) {
  const { id, submit, filesArray, ...data } = payload;
  try {
    if (submit) {
      // uploading known_defects files to firebase
      if (filesArray?.length > 0) {
        showMessage('upload.inprogress', null, ID.loading, ID.key);
        const files = yield Firebase.adminFilesToStorageFiles(
          STORAGE.immomove_files_process,
          filesArray,
        );
        showMessage('global.filesUploaded', null, ID.success, ID.key);
        data.answers[QuestionnaireScreen.known_defects.key].data.files = files;
      }
      yield database
        .collection(DB.im_termination_process)
        .doc(id)
        .update({ status: IM_PROCESS_STATUS.submitted.value });
      showMessage('questionnaire.answersUploadedToDatabase', null, ID.success);
    }
    yield database.collection(DB.im_termination_process).doc(id).update({
      answers: data.answers,
      time_updated: handleTimeStamps(),
    });
    yield put(actions.saveAnswers.success(submit));
  } catch (error) {
    Reporting.Error(error);
    yield put(actions.saveAnswers.failure('global.generalErrorMessage'));
  } finally {
    yield delay(1);
    yield put(actions.saveAnswers.fulfill());
  }
}

function* getUserAndProcess({ payload }) {
  const { uid, getFiles } = payload;
  try {
    const userDoc = yield call(
      rsfFirestore.getCollection,
      database.collection(DB.ia_users).doc(uid),
    );
    const data = userDoc.data();
    if (!_.isEmpty(data)) {
      const snapshot = yield call(
        rsfFirestore.getCollection,
        database
          .collection(DB.im_termination_process)
          .where('tenant.uid', '==', uid),
      );
      const terminationData = snapshotAsArray(snapshot, true);
      if (!_.isEmpty(terminationData)) {
        data.termination = terminationData[0];
        if (data.termination.status < IM_PROCESS_STATUS.accessed.value) {
          yield database
            .collection(DB.im_termination_process)
            .doc(data.termination.id)
            .update({ status: IM_PROCESS_STATUS.accessed.value });
        }
        if (
          getFiles &&
          data.termination.files_common &&
          !_.isEmpty(data.termination.files_common)
        ) {
          const filesDoc = yield call(
            rsfFirestore.getCollection,
            database.collection(DB.im_files_common).doc(DB.doc_files_common),
          );
          const commonFiles = filesDoc.data().files.filter((file) => {
            return data.termination.files_common.includes(file.id);
          });
          data.termination.files_process =
            data.termination.files_process.concat(commonFiles);
        }
      }
    }
    yield put(actions.getUserAndProcess.success(data));
  } catch (error) {
    Reporting.Error(error);
    yield put(actions.getUserAndProcess.failure('global.generalErrorMessage'));
  } finally {
    yield delay(1);
    yield put(actions.getUserAndProcess.fulfill());
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(
      actions.subscribeTerminationProcesses.TRIGGER,
      subscribeTerminationProcesses,
    ),
    takeEvery(
      actions.getTerminationProcessDetails.TRIGGER,
      getTerminationProcessDetails,
    ),
    takeEvery(actions.getCommonFiles.TRIGGER, getCommonFiles),
    takeEvery(actions.terminationAction.TRIGGER, terminationAction),
    takeEvery(actions.updateTerminationStatus.TRIGGER, updateTerminationStatus),
    takeEvery(actions.resendAccess.TRIGGER, resendAccess),
    takeEvery(actions.sendVoucher.TRIGGER, sendVoucher),
    takeEvery(actions.saveAnswers.TRIGGER, saveAnswers),
    takeEvery(actions.getUserAndProcess.TRIGGER, getUserAndProcess),
  ]);
}
