import { Layout, notification } from 'antd';
import siteConfig from 'config/site.config';
import dayjs from 'dayjs';
import { ID, ScreenLayout } from 'definitions/constants-fe';
import { getSwitcherIconBorderColor } from 'library/functionHelper';
import { throttle } from 'lodash';
import { Component } from 'react';
import { connect } from 'react-redux';
import WindowResizeListener from 'react-window-size-listener';
import actionsApp from 'redux/global/app/actions';
import { RootState } from 'redux/root-saga';
import HorizontalMenu from '../HorizontalMenu/HorizontalMenu';
import Topbar from '../Topbar/Topbar';
import AppRouter from './AppRouter';

interface ClassPropsDirect {}

interface ClassPropsRedux {
  errorMessage: string;
  location: any;
  match: any;
  rx_height: number;
  toggleAll: ({ width, height }: { width: number; height: number }) => void;
}

type ClassProps = ClassPropsDirect & ClassPropsRedux;

interface ClassState {
  isDesktop: boolean;
  menuSelect: boolean;
}

class App extends Component<ClassProps, ClassState> {
  constructor(props: ClassPropsDirect) {
    // @ts-ignore
    super(props);
    this.state = {
      isDesktop: false,
      menuSelect: false,
    };
    this.updatePredicate = this.updatePredicate.bind(this);
    this.toggleMenuSelect = this.toggleMenuSelect.bind(this);
  }

  componentDidMount() {
    this.updatePredicate();
    window.addEventListener('resize', this.updatePredicate);
  }

  componentDidUpdate(prevProps: ClassProps) {
    if (
      this.props.errorMessage &&
      this.props.errorMessage !== prevProps.errorMessage
    ) {
      this.openNotificationWithIcon('error', this.props.errorMessage);
    }
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.toggleMenuSelect();
    }
  }

  toggleMenuSelect() {
    this.setState((prevState) => {
      return { menuSelect: !prevState.menuSelect };
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updatePredicate);
  }

  updatePredicate = throttle(() => {
    const newIsDesktop = window.innerWidth >= 768;
    if (this.state.isDesktop !== newIsDesktop) {
      this.setState({ isDesktop: newIsDesktop });
    }
  }, 200);

  openNotificationWithIcon = (type: string, msg: string) => {
    // @ts-ignore
    notification[type]({
      message: type,
      description: msg,
    });
  };

  render() {
    const { rx_height } = this.props;
    const { isDesktop, menuSelect } = this.state;

    const currentUrl = this.props.location.pathname.split('/');
    let heightCount = ScreenLayout.min_heights.mobile;
    if (window.innerWidth >= 768) {
      if (currentUrl.includes(ID.immoapp)) {
        heightCount = ScreenLayout.min_heights.with_topbar;
      } else {
        heightCount = ScreenLayout.min_heights.without_topbar;
      }
    }
    const switcherIconBorderClass = getSwitcherIconBorderColor() ?? null;

    return (
      <Layout>
        <WindowResizeListener
          onResize={(windowSize) =>
            this.props.toggleAll({
              width: windowSize.windowWidth,
              height: windowSize.windowHeight,
            })
          }
        />
        {/* the history was passed here as in the topbar, we have the logos which should redirect to correct home screen on click. And windows.location sometimes passes inconcistent urls, so to avoid such errors, history props was passed from here */}
        <Topbar switcherIconBorderClass={switcherIconBorderClass} />
        <Layout className="flex-row overflow-x-hidden">
          {/* @ts-ignore */}
          {isDesktop && <HorizontalMenu routechanged={menuSelect} />}
          <Layout
            className="isoContentMainLayout"
            style={{ height: rx_height }}
          >
            <Layout.Content
              className="isomorphicContent flex-shrink-0 bg-[#f1f3f6] relative"
              style={{
                minHeight: `calc(100vh - ${heightCount}px)`,
              }}
            >
              <AppRouter />
            </Layout.Content>
            <Layout.Footer className="bg-white text-center leading-normal border-t border-[#ededed]">
              {'©'} {dayjs().year()} {siteConfig.footerText}
            </Layout.Footer>
          </Layout>
        </Layout>
      </Layout>
    );
  }
}

export default connect(
  (state: RootState) => ({
    auth: state.GL_Auth,
    rx_height: state.GL_App.rx_height,
  }),
  { ...actionsApp },
)(App);
